<template>
    <transition name="fade">
    <div class="sports-wrapper page">
        <div class="secondary-header" v-if="ready">
            <div @click="() => { this.$router.push({path: '/sports'})}" >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"> <path d="M15 6L9 12L15 18" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                Sports
            </div>

            <div class="save">
                <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" fill="none"> <path d="M18 8.4C18 6.70261 17.3679 5.07475 16.2426 3.87452C15.1174 2.67428 13.5913 2 12 2C10.4087 2 8.88258 2.67428 7.75736 3.87452C6.63214 5.07475 6 6.70261 6 8.4C6 15.8667 3 18 3 18H21C21 18 18 15.8667 18 8.4Z" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M13.73 21C13.5542 21.3031 13.3019 21.5547 12.9982 21.7295C12.6946 21.9044 12.3504 21.9965 12 21.9965C11.6496 21.9965 11.3054 21.9044 11.0018 21.7295C10.6982 21.5547 10.4458 21.3031 10.27 21" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
            </div>
        </div>

        <div class="details" v-if="ready">
            <div class="bg" ref="bg"/>
                <div class="head">
                    <div class="info">

                        <div class="logos">
                            <div class="logo" v-for="(t, j) in sport.logos" :key="j" >
                                <img :src="t" loading="lazy" ref="img"/>
                            </div>
                        </div>

                        <div class="clock">
                            <p> {{sport.score}} </p>
                            {{sport.displayTime}}
                        </div>

                        <h2> {{sport.name}} </h2>

                    </div>
                </div>
            </div>

            <div class="line" v-if="ready">
                <span> {{ sport.displayDate }} </span>
                <p>
                    {{sport.sportName}}
                    <img :src="sport.sportLogo"/>
                </p>
            </div>

            <div class="stream-wrapper">
                <h3> Streams </h3>
                <div class="streams">
                    <template v-for="(s,i) in sport.streams">
                        <div class="stream" @click="play(s)" :key="i" v-ripple>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none"> <path d="M6.90588 4.53682C6.50592 4.2998 6 4.58808 6 5.05299V18.947C6 19.4119 6.50592 19.7002 6.90588 19.4632L18.629 12.5162C19.0211 12.2838 19.0211 11.7162 18.629 11.4838L6.90588 4.53682Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                            {{s.name}}
                        </div>
                    </template>

                </div>
            </div>


            <div class="competitions">
                <h3 v-if="sport.isTeamSport"> Events </h3>

                <div class="tabs" v-if="!sport.isTeamSport">
                    <div class="tab" v-for="(t,i) in sport.competitions" :key="i" :class="{active: isActiveTab(i)}" @click="changeTab(i)" v-ripple>
                        {{getTabName(t)}}
                    </div>
                </div>

                <div class="competition" v-if="ready">
                    <div class="de">
                        <div v-if="sport.circuit">
                            <small> circuit </small>
                            <br/>
                            {{sport.circuit.fullName}}
                        </div>

                        <div v-if="sport.competitions[activeCompetition].venue">
                            <small> venue </small>
                            <br/>
                            {{sport.competitions[activeCompetition].venue.fullName}}
                        </div>

                        <div v-if="sport.competitions[activeCompetition].status">
                            <small> status </small>
                            <br/>
                            {{sport.competitions[activeCompetition].status.type.detail}}
                        </div>

                        <div v-if="sport.name && sport.name.includes('UFC')">
                            <small> class </small>
                            <br/>
                            {{sport.competitions[activeCompetition].type.abbreviation}}
                        </div>
                    </div>
                </div>
            </div>

            <div class="competitions">
                <div class="competition" v-if="ready">
                    <div class="competition ath">

                        <div class="athlete" v-for="(t, j) in sport.athletes.filter((x) => { return x.competition === sport.competitions[activeCompetition].id })" :key="j" >
                            <div :class="{winner: t.winner}">
                                <small v-if="t.leader"> {{ t.leader }} </small>
                                <img :src="t.headshot" loading="lazy"/>
                                {{t.displayName}}
                            </div>
                        </div>
                    </div>

                </div>
           </div>

            <div v-if="ready" class="bcs">
                <h3> Broadcasts </h3>
                <div class="content-row broad" v-for="(g,i) in sport.guides" :key="i" >
                    <ListGuide :guide="g" v-ripple/>
                </div>
                <div v-if="sport.guides.length == 0" class="sorry">  sorry no broadcasts found </div>
            </div>

            <div class="section" v-if="ready">
                <h3> Other Sports </h3>
                <div class="content-row" >
                    <SportComp :sport="c" v-for="(c,i) in sport.related" :key="i" class="sport" v-ripple/>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
const ListGuide = () =>  import('/src/components/ListGuide.vue')
const SportComp = () =>  import('/src/components/Sport.vue')

export default {
  name: 'SportView',

  components: {
    ListGuide,
    SportComp
  },


    mounted: async function(){
        await this.loadDetails()
        this.reloadTimer = setInterval(this.loadDetails.bind(this), 300000);
    },

  watch: {
      $route() {
        clearInterval(this.reloadTimer);
        this.ready = false
        this.activeCompetition = 0
        this.loadDetails()
        this.reloadTimer = setInterval(this.loadDetails, 300000);
      }
  },

  data: function(){
    return {
    reloadTimer: null,
       ready: false,
       sport: false,
       activeCompetition: 0
    }
  },

  methods: {
    isActiveTab(i){
        return i == this.activeCompetition
    },

    changeTab(i){
        this.activeCompetition = i
    },

    getTabName(t){
        if(this.sport.name && this.sport.name.includes('UFC')){
            return `${t.competitors[0].athlete.shortName.split('. ')[1]} v ${t.competitors[1].athlete.shortName.split('. ')[1]}`
        }
        return t.type.abbreviation || ''
    },


     loadDetails: async function(){
        if(!this.$route.params.sport || !this.$route.params.leauge){
            clearInterval(this.reloadTimer);
            return
        }
        const res = await fetch(`${process.env.VUE_APP_SERVER}/sport?sport=${this.$route.params.sport}&leauge=${this.$route.params.leauge}&eventId=${this.$route.params.eventId}`)
        const data = await res.json()
        this.sport = data
        this.$nextTick(() => {
            this.ready = true
            this.$nextTick(() => {
                if(this.sport.colors){
                    this.$refs.bg.style.backgroundImage = this.sport.colors
                }
            })
        })
    },

    play: function(s){
        if(!window.did){
            this.$root.$emit('collectDid', {cb: this.play.bind(this), params: s})
            return
        }

        let data = {
            type: 'sport',
            id: this.$route.params.eventId,
            did: window.did,
            ref: window.location.href,
            url: s.link,
            name: this.sport.name,
            color: this.sport.colors,
            logos: this.sport.logos,
            details: {
                shortName: this.sport.shortName,
                score: this.sport.score,
                date: this.sport.displayDate,
                sport: this.$route.params.sport,
                leauge: this.$route.params.leauge,
                competitions: this.sport.competitions,
                athletes: this.sport.athletes,
                circuit: this.sport.circuit,
            }
        }

        fetch(process.env.VUE_APP_SERVER + `/start`, {method: 'POST', body: JSON.stringify(data), headers: { "Content-Type": "application/json" } })
    },


  },

  computed: {

  },
}

</script>

<style scoped lang="scss">
.sports-wrapper{
    padding: 0;
    padding-top: 60px;
    .details{
        position: relative;
        padding: 0 20px;
        padding-bottom: 10px;
        z-index: 2;
    }
    .bg{
        position: absolute;
        padding: 0 20px;
        top: -60px;
        left: -20px;
        width: 100vw;
        height: calc(100% + 60px);
        border-bottom-right-radius: 40px;
        border-bottom-left-radius: 40px;
        z-index: -1;
        background-image: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
        background-size: 400% 400%;
        overflow: hidden;
        animation: gradient 15s ease infinite;
        .content{
            width: 100%;
        }

        @keyframes gradient { 0% {
                background-position: 0% 50%;
            }
            50% {
                background-position: 100% 50%;
            }
            100% {
                background-position: 0% 50%;
            }
        }
        :after{
            content: ' ';
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            background-color: #111;
            opacity: 0.2;
            z-index: -1;
        }

    }
    .clock{
        font-size: 9px;
        text-align: center;
        p{
            margin: 0;
            padding-bottom: 20px;
        }
    }
    h2{
        font-size: 11px;
        text-align: center;
    }

    .logos{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        >img{
            width: 80%;
            object-fit: contain;
            &.f1{
                width: 120%;
                max-height: 300px;
            }
            &.ufc{
                max-width: 70px;
            }
        }
        .logo{
            width: 80px;
            margin: 20px;
            img{
                width: 100%;
            }
        }
    }
}
.line{
    display: flex;
    padding: 0 20px;
    flex-direction: row;
    justify-content: space-between;
    font-size: 9px;
    margin-top: 20px;
    align-items: center;
    font-weight: bold;
    span{
        text-transform: uppercase;
    }
    p{
        display: flex;
        align-items: center;
    }
    img{
        height: 20px;
        border-radius: 3px;
        margin-left: 10px;
    }
    p{
        margin: 0;
    }
}
.bcs{
    margin-bottom: 30px;
}
.content-row.broad{
    margin: 0;
    a{
        margin-bottom: 10px;
    }
}
.tabs{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 20px;
    .tab{
        margin-right: 5px;
        border: 1px solid white;
        padding: 5px 10px;
        border-radius: 5px;
        text-align: center;
        margin-bottom: 10px;
        font-size: 9px;
        &.active{
            color: black;
            background-color: white;
        }
    }
}

.streams{
    display: flex;
    flex-direction: row;
    margin:20px 0;
    margin-bottom: 30px;
    padding-left: 20px;
    overflow-x: scroll;
    align-items: center;
    height: 75px;
    svg{
        height: 15px;
        margin-bottom: 10px;
        margin-top: 5px;
    }

    .stream{
        font-size: 9px;
        border: 1px solid white;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #111;
        margin-right: 10px;
        padding: 5px 10px;
        padding-bottom: 10px;
        box-sizing: border-box;
        border-radius: 5px;
        min-width: 100px;
        overflow: hidden;
        text-align: center;
    }
}
.competitions{
    padding: 0 20px;
    h3{
      margin-left: 0px;
    }

    .competition{
        font-size: 9px;
        justify-content: center;
        > div{

        }
        small{
            display: block;
            font-size: 7px;
            padding-bottom: 5px;
            font-weight: bold;
        }

        span{
            font-size: 6px;
            text-align: left;
            width: 200%;
            margin-bottom: 5px;
        }

        img{
            width: 40px;
            height: 40px;
            object-fit: cover;
        }

        &.ath{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            padding-top: 20px;
            position: relative;

            small{
                font-size: 6px;
                font-weight: bold;
                text-transform: uppercase;
                position: relative;
                left: -10px;
                flex-grow: 1;
                text-align: left;
                width: 40%;
                padding-right: 60%;
            }
        }

        .athlete >div {
            display: flex;
            flex-direction: column;
            width: 70px;
            margin: 10px;
            justify-content: center;
            font-size: 9px;
            text-align: center;
            align-items: center;
            position: relative;
            &.winner{
                img{
                    border: 2px solid #2ecc71;
                }

                &:after{
                    content: '✓';
                    width: 5px;
                    height: 5px;
                    padding: 7.5px;
                    font-size: 9pt;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    right: 0;
                    background-color: #2ecc71;
                    border-radius: 50%;
                    bottom: 20px;
                    z-index: 1;
                }
            }
            span{
                width: 100%;
                padding: 0;
                margin: 5px 0;
                text-align: center;
            }
            img{
                background-color: white;
                border-radius: 50%;
                margin-bottom: 5px;
            }
        }
        .de{
            display: flex;
            justify-content: center;
            small{
                display: inline;
            }
            >div{
                margin-right: 15px;
                padding-right: 5px;
            }
        }
    }

}

h3{
  margin-left: 20px;
}
.sport{
    min-width: 150px;
    margin-right: 10px;
}

.sorry{
    padding: 5px 20px;
}

</style>
