import { render, staticRenderFns } from "./Sport.vue?vue&type=template&id=27912c88&scoped=true"
import script from "./Sport.vue?vue&type=script&lang=js"
export * from "./Sport.vue?vue&type=script&lang=js"
import style0 from "./Sport.vue?vue&type=style&index=0&id=27912c88&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27912c88",
  null
  
)

export default component.exports